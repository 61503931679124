<template>
  <div class="login">
    <div class="login-container">
        <el-form ref="form" :model="form" label-width="70px" class="form">
          <el-form-item label="用户名">
            <el-input v-model="form.username" placeholder="请输入用户名"></el-input>
          </el-form-item>
          <el-form-item label="密码">
            <el-input type="password" v-model="form.password" placeholder="请输入密码"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submitForm()">登陆</el-button>
            <el-button @click="resetForm()">重置</el-button>
          </el-form-item>
        </el-form>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Login',
  data() {
    return {
      form:{
        username:'',
        password:''
      }
    }
  },
  methods: {
    submitForm(){
      if(!this.form.username || !this.form.password){
        this.$message({
          message: '用户名和密码不能为空',
          type: 'warning'
        });
        return
      }
      this.$axios({        
        method: 'post', //请求方法 get post
        url: 'http://www.cqcode.cn/api/catz/admin/login',  //请求地址
        data: {
          username:this.form.username,
          password:this.form.password
        },  //向请求地址传递参数 通常用于post 不体现在请求地址
      }).then((result) => {
          console.log(result);
        if(result.data.status == 200){
          sessionStorage.setItem("jwt_token",JSON.stringify(result.data.data))
          this.$router.push({name:'Home'})
          this.$message({
            message: '登录成功',
            type: 'success'
          });
        }else{
          this.$message({
            message: result.data.errMsg,
            type: 'error'
          });
        }
      }).catch(err => {
        console.log(err);
      })
    },
    resetForm(){
      this.form.username = ''
      this.form.password = ''
    }
  },
}
</script>
<style lang="less" scoped>
.login{
    background: linear-gradient(139.73deg,#f3efff 100%,#e5fdff 0);
    //   background: url('https://timgsa.baidu.com/timg?image&quality=80&size=b9999_10000&sec=1607514350578&di=e7d983038138de498321bb70790df343&imgtype=0&src=http%3A%2F%2Fc-ssl.duitang.com%2Fuploads%2Fitem%2F201403%2F23%2F20140323154939_afzVy.jpeg');
    //   background-size: cover;
    width: 100%;
    height: 100vh;
}
.login-container {
    background-color: rgba(255,255,255,.66);
    box-shadow: 0px 0px 6px 0px rgb(121,120,120,50%);
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    font-size: 1rem;
    padding: 40px 40px 20px 40px;
    width: 100%;
    max-width: 350px;
}
</style>