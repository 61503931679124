import request from '@/utils/axios'
// 获取概览
export function overview(data) {
  return request({
    url: '/user/admin/overview',
    method: 'post',
    data
  })
}

// 封禁测试
// {
//   "domain":"lanx", 域名
//   "dd":11, 日期
//   "hh":"12" 小时 12小时制
// }
export function wx110(data) {
  return request({
    url: '/user/admin/wx110',
    method: 'post',
    data
  })
}

// 批量评级
// {
//   "oldRating":"-1", 旧等级
//   "newRating":"-2"  新等级
// }
export function updateRatingsAll(data) {
  return request({
    url: '/user/admin/ratings/update/all',
    method: 'post',
    data: data
  })
}

// 单个评级
// {
//   "uid":"63e0a15ebaa50d6d6a54f04d", //用户的_id
//   "rating":1 //评级 -3 -2 -1 0 1 2 3
// }
export function updateRating(data) {
  return request({
    url: '/user/admin/ratings/update',
    method: 'post',
    data: data
  })
}

// 查询接口
// user/admin/find/users
// {
//     "rating": 0,
//     "page": 1,
//     "limit": 50,
//     "views": 100,
//     "create_start_time": "2020-01-05",
//     "create_end_time":"2024-01-10"
// }
export function query(data) {
  return request({
    url: 'user/admin/find/users',
    method: 'post',
    data: data
  })
}

// 获取dlink
export function getDlink(data) {
  return request({
    url: 'user/admin/dlink/get',
    method: 'post',
    data: data
  })
}

// 更新dlink
export function updateDlink(data) {
  return request({
    url: 'user/admin/dlink/update',
    method: 'post',
    data: data
  })
}

// 举报中心
export function getReportsList(data) {
  return request({
    url: 'user/admin/find/reports',
    method: 'post',
    data: data
  })
}

// 举报中心处理结果
export function toReport(data) {
  return request({
    url: 'user/admin/solution/report',
    method: 'post',
    data: data
  })
}

// 快速获取用户token
export function getToken(data) {
  return request({
    url: 'user/admin/change/password',
    method: 'post',
    data: data
  })
}

// 发送通知
export function notify(data) {
  return request({
    url: 'admin/notify/new',
    method: 'post',
    data: data
  })
}