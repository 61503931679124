import axios from 'axios'

// axios 配置
axios.defaults.timeout = 1000 * 30
 // 设置请求的baseUrl----基准请求路径
axios.defaults.baseURL = 'https://apiv7.vlink.cc'    //线上
// axios.defaults.baseURL = 'https://api.linktree.cn'    //线上
// axios.defaults.baseURL = 'http://127.0.0.1:3000'  //本地


// http request 拦截器
axios.interceptors.request.use(
  config => {
    if (typeof window !== 'undefined') {
      const token = localStorage.getItem('LINKTREE_TOKEN'); 
      if (token) {
        config.headers.Authorization = `Bearer ${token}` 
      }
    }
    return config
  },
  err => {
    return Promise.reject(err)
  },
)

// http response 拦截器
axios.interceptors.response.use(
  res => {
    if (res && res.data) {
      return res.data
    }else{
      return res
    }
  },
  error =>{
    // console.error('Request error:', error);
    // console.log('error=>',error.response.data)
    // if (error.response.data.code === -1) {
    //   console.error(error.response.data.message);
    // }
  }
)

let ajax = function(config) {
    config.url = config.url

    return new Promise((resolve, reject)=>{
        var options = {
            method: config.method,
            headers: {
                // 'Content-Type': 'application/x-www-form-urlencoded',
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },  
            data: config.data 
        }
        axios(config.url, options).then(res => {
            resolve(res)
        })
    })
}

export default ajax;
