import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'
import Home from '../views/Home.vue'
import reports from '../views/reports.vue'

Vue.use(VueRouter)
const mode = "hash"
const routes = [
  {
    path: '/',
    name: 'Home', 
    component: Home 
  },
  // {
  //   path: '/',
  //   name: 'Login',
  //   component: Login
  // },
  {
    path: '/Home',
    name: 'Home',
    component: Home
  },{
    path: '/reports',
    name: 'Reports',
    component: reports
  },
]

const router = new VueRouter({
  mode,
  routes
})


export default router
