<template>
    <div class="report">
        <el-card  style="margin-bottom: 5px;">
            <div class="cardNumber">
                <div class="card-number">
                    <div>全部</div>
                    <div class="card-num" @click="getList(2,totalReports)">{{ totalReports }}</div>
                </div>
                <div class="card-number">
                    <div>待处理</div>
                    <div class="card-num" @click="getList(0,pendingReports)">{{ pendingReports }}</div>
                </div>
                <div class="card-number">
                    <div>已处理</div>
                    <div class="card-num" @click="getList(1,processedReports)">{{ processedReports }}</div>
                </div>
            </div>
        </el-card>
        <!-- 表格数据 -->
        <el-table
        :data="List"
        stripe
        style="width: 100%" 
        :default-sort = "{prop: 'date', order: 'descending'}">
            <el-table-column
            prop="link"
            key="link"
            label="链接"
            align="center"
            width="140">
            <template slot-scope="scope">
                <span :style="{ color: isVlink(scope.row.link) ? 'red' : 'inherit',cursor: 'pointer' }" @click="goLink(scope.row.link)">{{ extractLink(scope.row.link) }}</span>
            </template>
            </el-table-column>
            <el-table-column
            prop="category"
            key="category"
            label="举报类型"
            align="center"
            sortable
            width="130">
            <template slot-scope="scope">
                <span style="cursor: pointer" @click="copy(extractLink(scope.row.link))">{{ scope.row.category }}</span>
            </template>
            </el-table-column>
            <el-table-column
            prop="previous"
            key="previous"
            label="来源"
            align="center"
            width="140">
            <template slot-scope="scope">
                <span>{{ extractPrevious(scope.row.previous) }}</span>
            </template>
            </el-table-column>
            <el-table-column
            prop="description"
            key="description"
            label="举报描述"
            align="center">
            </el-table-column>
            <el-table-column
            prop="ip"
            key="ip"
            label="举报人ip "
            align="center"
            width="140">
            <template slot-scope="scope">
                <span style="cursor: pointer" @click="informant(scope.row)">{{ scope.row.ip }}</span>
            </template>
            </el-table-column>
            <el-table-column
            prop="solution"
            key="solution"
            label="解决方案"
            align="center"
            v-if="solution">
            </el-table-column>
            <el-table-column
            prop="createdAt"
            key="createdAt"
            label="举报时间"
            align="center">
            <template slot-scope="scope">
                <span>{{ formatDate(scope.row.createdAt) }}</span>
            </template>
            </el-table-column>
            <el-table-column
            prop="updatedAt"
            key="updatedAt"
            label="解决时间"
            align="center"
            v-if="solution">
            <template slot-scope="scope">
                <span>{{ formatDate(scope.row.updatedAt) }}</span>
            </template>
            </el-table-column>
            <el-table-column
            label="操作"
            align="center"
            v-if="!solution">
            <template slot-scope="scope">
                <el-button type="primary" icon="el-icon-edit" plain size="small" @click="toSolutionCode(scope.row._id)">已处理</el-button>
            </template>
            </el-table-column>
        </el-table>
        <div>
            <el-dialog title="举报人信息" :visible.sync="detailData">
                <el-form :model="informantDetails" class="detailData">
                    <el-form-item label="ip：">{{ informantDetails.ip }}</el-form-item>
                    <el-form-item label="地区：">{{ informantDetails.source }}</el-form-item>
                    <el-form-item label="链接：">{{ informantDetails.user }}</el-form-item>
                    <el-form-item label="手机号：">{{ informantDetails.phone }}
                        <button @click="copy(informantDetails.phone)" class="copeButton" v-if="informantDetails.phone">复制</button>
                    </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer">
                    <el-button type="primary" @click="detailData = false">确 定</el-button>
                </div>
            </el-dialog>
        </div>
        <div>
            <el-dialog title="处理结果" :visible.sync="solutionCode">
                <el-input v-model="solutionInput" placeholder="请输入内容" clearable></el-input>
                <div slot="footer" class="dialog-footer">
                    <el-button @click="solutionCode = false">取 消</el-button>
                    <el-button type="primary" @click="toReportCode()">确 定</el-button>
                </div>
            </el-dialog>
        </div>
        <el-pagination
            background
            @current-change="handleCurrentChange"
            layout="prev, pager, next"
            :total="pageAll"
            class="pagination">
        </el-pagination>
    </div>
</template>
  
<script>
import { getReportsList,toReport } from '@/api/admin';
import copyText from 'copy-to-clipboard';
import dayjs from 'dayjs'; 


export default {
    name: 'Reports',
    data() {
        return {
            page: 1,
            limit: 100,
            solution: 0,// 0待处理 1已处理 2全部
            url: '' ,
            List: [],
            informantDetails:'',
            detailData: false,
            solutionCode: false,
            solutionInput: '',
            userId:'',
            pendingReports:'',
            processedReports:'',
            totalReports:'',
            pageAll:0
        }
    },
    mounted(){
        this.getList()
    },
    methods: {
        getList(e,num){
            if(e){
                this.solution = e
            }else{
                this.solution = 0
            }
            getReportsList({
                url: this.url,
                solution: this.solution,
                page: this.page,
                limit: this.limit
            }).then(res=>{
                this.List = res.data.reports
                this.pendingReports = res.data.pendingReports,
                this.processedReports = res.data.processedReports,
                this.totalReports = res.data.totalReports
                if(num){
                    this.pageAll = Math.ceil(num/100)*10
                }else{
                    this.pageAll = Math.ceil(this.pendingReports/100)*10
                }
            })
        },
        extractLink(url) {
            const path = url.split('/').pop();
            return path.split('?')[0];
        },
        extractPrevious(url) {
            const regex = /\/([^/?#]+)(?:[/?#]|$)/i;
            const matches = url.match(regex);
            return matches && matches[1] ? matches[1] : url;
        },
        isVlink(url) {
            return url.startsWith('https://vlink.cc');
        },
        // 跳转链接
        goLink(url){
            window.open(url)
        },  
        // 格式化日期
        formatDate(date) {  
            return dayjs(date).format('YYYY-MM-DD HH:mm:ss'); // 格式化日期
        }, 
        informant(row){
            this.informantDetails = row
            this.detailData = true
        },
        // 复制
        copy(e){
            copyText(e)
                this.$message({
                    message: '复制成功',
                    type: 'success'
                });
        },
        handleCurrentChange(val) {
            this.page = val
            this.getList()
        },
        toSolutionCode(id){
            this.solutionCode = true
            this.userId = id
        },
        toReportCode(){
            toReport({
                _id: this.userId,
                solution: this.solutionInput,
            }).then(res=>{
                console.log(res)
                this.solutionCode = false
                this.List = this.List.filter(item=>{
                    return item._id != this.userId
                })
            })
        }
    },
}
</script>
<style lang="less" scoped>
.cardNumber{
  display: flex;
  width: 100%;
}
.card-number{
  text-align: center;
  float: left;
  width: 25%;
}
.card-num{
  font-size: 26px;
  cursor: pointer
}
.copeButton{
  color: #369fc9;
  font-size: 12px;
  background-color: #fff;
  border: 0;
  margin-left: 10px;
  cursor: pointer
}
.pagination{
    margin: 30px;
    display: flex;
    justify-content: flex-end;
}
</style>