<template>
  <div id="app">
    <div class="sidebar-container" v-show="showMenu">
      <Sidebar />
    </div>
    <div @click="changeMenu" v-if="!showMenu"
    style="position: absolute;left:5px;top:5px;display:flex;align-items: center;font-size: 16px;background: #fff;z-index: 999;border: 1px solid #888;padding: 3px;border-radius: 5px;">
      <div>菜单</div>
      <i class="el-icon-d-arrow-right" style="font-size: 16px"></i>
    </div>
    <div class="content-container">
      <router-view />
    </div>
  </div>
</template>

<script>
import Sidebar from '@/components/Sidebar.vue';
import { mapState, mapActions } from 'vuex';
export default {
  name: 'App',
  components: {
    Sidebar
  },
  computed: {
    ...mapState(['showSidebar']),
    showMenu() {
      console.log(111,this.$store.state.showSidebar);
      console.log(222,this.$store.state.clickCount);
      return this.showSidebar;
    }
  },
  methods: {
    ...mapActions(['toggleSidebar']),
    changeMenu(){
      this.toggleSidebar()
    },
  },
  mounted(){
    if (window.innerWidth < 1024) {
      this.changeMenu()
    }
  }
}
</script>

<style>
#app {
  display: flex;
  height: 100vh;
}

.sidebar-container {
  flex: 0 0 200px; /* 设置侧边栏宽度 */
  height: 100%;
}

.content-container {
  flex: 1; /* 内容区域占据剩余空间 */
  padding: 20px;
  overflow-y: auto; /* 内容过多时显示滚动条 */
}
@media (max-width: 1024px) {
  .content-container {
    padding: 0px;
  }
}
</style>