import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    clickCount: 0,
    showSidebar: true,
  },
  mutations: {
    incrementClickCount(state) {
      state.clickCount++
    },
    toggleSidebar(state) {
      state.showSidebar = !state.showSidebar;
    }
  },
  actions: {
    toggleSidebar({ commit }) {
      commit('toggleSidebar');
    }
  },
  modules: {
  }
})
