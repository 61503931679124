<template>
    <el-menu
      class="el-menu-vertical-demo"
      background-color="#545c64"
      text-color="#fff"
      active-text-color="#ffd04b"
      :default-active="activeIndex"
      style="height:100vh;">
      <el-menu-item index="0" @click="handleClick('Home')">
        <i class="el-icon-search"></i>
        <span slot="title">查询用户</span>
      </el-menu-item>
      <el-menu-item index="3" @click="handleClick('Reports')">
        <i class="el-icon-edit"></i>
        <span slot="title">审核中心</span>
      </el-menu-item>
      <el-menu-item index="4" @click="hideMenu('Reports')">
        <i class="el-icon-d-arrow-left"></i>
        <span slot="title">隐藏菜单</span>
      </el-menu-item>
    </el-menu>
  </template>
  
  <script>
  import { mapState, mapActions } from 'vuex';
  export default {
    name: 'Sidebar',
    data() {
      return {
        clickCount: 0,
        currentRoute: '',
        activeIndex: '0'
      };
    },
    created() {
      this.setActiveIndex();
    },
    watch: {
      '$route': 'setActiveIndex' // 监听路由变化，重新设置高亮菜单项
    },
    methods: {
        ...mapActions(['toggleSidebar']),
        hideMenu(){
            this.toggleSidebar()
        },
        handleClick(routeName) {
            if (routeName === 'Home') {
                // 点击5次
                this.incrementCount()
                if (this.currentRoute !== 'Home') {
                    this.clickCount = 1;
                } else {
                    this.clickCount++;
                }
                console.log(this.clickCount)
            }
            if (this.$route.name !== routeName) {
                console.log(111, routeName)
                this.$router.push({ name: routeName, params: { batch: this.clickCount === 3 } });
            }
            this.currentRoute = routeName;
        },
        incrementCount() {
            this.$store.commit('incrementClickCount');
        },
        setActiveIndex() {
          const currentRouteName = this.$route.name;
          switch (currentRouteName) {
            case 'Home':
              this.activeIndex = '0';
              break;
            case 'Reports':
              this.activeIndex = '3';
              break;
            default:
              this.activeIndex = '0';
          }
        }
    }
  }
  </script>